<template>
  <div class="card-body">
    <div class="card-title">
      <div class="row">
        <div class="col">
          <h5>{{ title }}</h5>
        </div>

        <div v-show="showDelete" class="col-6 text-right">
          <button class="btn btn-danger" @click="remove">
            Ta bort deltagare
          </button>
        </div>
      </div>
    </div>
    <form>
      <div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="title">
                Förnamn
                <small class="text-muted">(obligatoriskt)</small>
              </label>
              <input
                id="title"
                type="text"
                class="form-control"
                autocomplete="given-name"
                v-model="participant.firstName"
                @input="$v.participant.firstName.$touch"
                :class="{
                  'is-invalid': $v.participant.firstName.$error,
                  'is-valid':
                    !$v.participant.firstName.$error &&
                    $v.participant.firstName.$dirty,
                }"
              />
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="title">
                Efternamn
                <small class="text-muted">(obligatoriskt)</small>
              </label>
              <input
                id="title"
                type="text"
                class="form-control"
                autocomplete="family-name"
                v-model="participant.lastName"
                @input="$v.participant.lastName.$touch"
                :class="{
                  'is-invalid': $v.participant.lastName.$error,
                  'is-valid':
                    !$v.participant.lastName.$error &&
                    $v.participant.lastName.$dirty,
                }"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">
            E-postaddress
            <small class="text-muted">(obligatoriskt)</small>
          </label>
          <input
            id="email"
            type="email"
            class="form-control"
            autocomplete="home email"
            v-model="participant.email"
            @input="$v.participant.email.$touch"
            :class="{
              'is-invalid': $v.participant.email.$error,
              'is-valid':
                !$v.participant.email.$error && $v.participant.email.$dirty,
            }"
          />
          <small id="emailHelp" class="form-text text-muted"
            >Använd gärna en privat mejladress istället för en mejladress som är
            anknuten till din skola, för att få alumniföreningens medlemsbrev
            efter du tagit studenten!</small
          >
        </div>
        <div class="form-group">
          <label for="confirmEmail">
            Bekräfta e-postaddress
            <small class="text-muted">(obligatoriskt)</small>
          </label>
          <input
            id="confirmEmail"
            type="email"
            class="form-control"
            autocomplete="home email"
            v-model="confirmEmail"
            @input="$v.confirmEmail.$touch"
            :class="{
              'is-invalid': $v.confirmEmail.$error,
              'is-valid': !$v.confirmEmail.$error && $v.confirmEmail.$dirty,
            }"
          />
          <div
            class="small text-danger"
            v-if="$v.confirmEmail.$dirty && !$v.confirmEmail.sameAs"
          >
            E-postadresserna matchar inte
          </div>
          <div
            class="small text-danger"
            v-if="$v.confirmEmail.$error && $v.confirmEmail.sameAs"
          >
            E-postadressen är ogiltig
          </div>
        </div>
        <div class="form-group">
          <label for="phone">
            Telefonnummer
            <small class="text-muted">(obligatoriskt)</small>
          </label>
          <input
            id="phone"
            type="tel"
            class="form-control"
            autocomplete="mobile tel"
            v-model="participant.phoneNumber"
            @input="$v.participant.phoneNumber.$touch"
            :class="{
              'is-invalid': $v.participant.phoneNumber.$error,
              'is-valid':
                !$v.participant.phoneNumber.$error &&
                $v.participant.phoneNumber.$dirty,
            }"
          />
        </div>

        <div class="form-group">
          <label for="street">
            Gatuaddress
            <small class="text-muted">(obligatoriskt)</small>
          </label>
          <input
            id="street"
            type="text"
            class="form-control"
            v-model="participant.address.street1"
            @input="$v.participant.address.street1.$touch"
            :class="{
              'is-invalid': $v.participant.address.street1.$error,
              'is-valid':
                !$v.participant.address.street1.$error &&
                $v.participant.address.street1.$dirty,
            }"
          />
        </div>

        <div class="form-group">
          <label for="zip-code">
            Postnummer
            <small class="text-muted">(obligatoriskt)</small>
          </label>
          <input
            id="zip-code"
            type="text"
            class="form-control"
            v-model="participant.address.zipCode"
            @input="$v.participant.address.zipCode.$touch"
            :class="{
              'is-invalid': $v.participant.address.zipCode.$error,
              'is-valid':
                !$v.participant.address.zipCode.$error &&
                $v.participant.address.zipCode.$dirty,
            }"
          />
        </div>

        <div class="form-group">
          <label for="city">
            Ort
            <small class="text-muted">(obligatoriskt)</small>
          </label>
          <input
            id="city"
            type="text"
            class="form-control"
            v-model="participant.address.city"
            @input="$v.participant.address.city.$touch"
            :class="{
              'is-invalid': $v.participant.address.city.$error,
              'is-valid':
                !$v.participant.address.city.$error &&
                $v.participant.address.city.$dirty,
            }"
          />
        </div>

        <div class="form-group" v-if="!isDigital">
          <label for="diet">Allergier/specialkost</label>
          <input
            id="diet"
            type="text"
            class="form-control"
            autocomplete="off"
            v-model="participant.diet"
          />
        </div>

        <!-- School Suggestions -->
        <div class="form-group">
          <label>
            Skola
            <small class="text-muted">(obligatoriskt)</small>
          </label>
          <div class="small text-danger" v-if="$v.participant.schoolId.$error">
            Du måste välja ett av alternativen i listan.
          </div>
          <school-picker
            @newSchool="newSchool"
            :schools="schools"
            @select="handleSelectSchool"
            :inputClass="schoolClass"
            ref="schoolPicker"
          />
        </div>

        <div
          class="form-group"
          v-if="$refs.schoolPicker && $refs.schoolPicker.editingSchool"
        >
          <label>
            Skolans ort <small class="text-muted">(obligatoriskt)</small>
          </label>
          <input
            type="text"
            :id="`schoolCity-${$vnode.key}`"
            class="form-control"
            autocomplete="off"
            v-model="createdSchool.address.city"
            @input="$v.createdSchool.address.city.$touch"
            :class="{
              'is-invalid': $v.createdSchool.address.city.$error,
              'is-valid':
                !$v.createdSchool.address.city.$error &&
                $v.createdSchool.address.city.$dirty,
            }"
          />
        </div>

        <div class="row">
          <div class="col col-md-6">
            <div class="form-group">
              <label for="dateField">Kön</label>
              <select
                class="form-control"
                id="genderField"
                autocomplete="sex"
                value
                v-model="participant.gender"
              >
                <option>Kvinna</option>
                <option>Man</option>
                <option>Ickebinär</option>
                <option>Vill ej uppge</option>
              </select>
              <small id="genderHelp" class="form-text text-muted"
                >Med kön menar vi könsidentitet, alltså det kön du själv känner
                dig som. Vi ställer frågan av statistiska skäl, och den är
                valfri att svara på.</small
              >
            </div>
          </div>
          <div class="col col-md-6">
            <div class="form-group">
              <label for="dateField">
                Födelsedatum
                <small class="text-muted">(obligatoriskt)</small>
              </label>
              <input
                class="form-control"
                type="date"
                id="dateField"
                placeholder="yyyy-mm-dd"
                autocomplete="bday"
                v-model="participant.birthDate"
                @blur="$v.participant.birthDate.$touch"
                :class="{
                  'is-invalid': $v.participant.birthDate.$error,
                  'is-valid':
                    !$v.participant.birthDate.$error &&
                    $v.participant.birthDate.$dirty,
                }"
              />
              <small class="form-text text-muted"
                >Du kan delta fram till och med det år du fyller 20.</small
              >
            </div>
          </div>
        </div>

        <!--  2023.09.17:
      There was a file that was used to handle multiple emergency contacts.
      It was deleted due to the request of each participant only having 1 emergency contact,
      as well as to enable validations for emergency contacts and making it mandatory to fill in. -->
        <div v-if="!isDigital">
          <div class="card bg-light my-3">
            <div class="card-body">
              <div class="card-title">
                <div class="row">
                  <div class="col">
                    <h6>
                      Nödkontakt
                      <small class="text-muted">(obligatoriskt)</small>
                    </h6>
                  </div>
                </div>
              </div>
              <div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="title">
                        Förnamn
                        <small class="text-muted">(obligatoriskt)</small>
                      </label>
                      <input
                        id="title"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        v-model="participant.emergencyContacts.firstName"
                        @input="
                          $v.participant.emergencyContacts.firstName.$touch
                        "
                        :class="{
                          'is-invalid':
                            $v.participant.emergencyContacts.firstName.$error,
                          'is-valid':
                            !$v.participant.emergencyContacts.firstName
                              .$error &&
                            $v.participant.emergencyContacts.firstName.$dirty,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="title">
                        Efternamn
                        <small class="text-muted">(obligatoriskt)</small>
                      </label>
                      <input
                        id="title"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        v-model="participant.emergencyContacts.lastName"
                        @input="
                          $v.participant.emergencyContacts.lastName.$touch
                        "
                        :class="{
                          'is-invalid':
                            $v.participant.emergencyContacts.lastName.$error,
                          'is-valid':
                            !$v.participant.emergencyContacts.lastName.$error &&
                            $v.participant.emergencyContacts.lastName.$dirty,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="phone">
                    Telefonnummer
                    <small class="text-muted">(obligatoriskt)</small>
                  </label>
                  <input
                    id="phone"
                    type="tel"
                    class="form-control"
                    autocomplete="mobile tel"
                    v-model="participant.emergencyContacts.phoneNumber"
                    @input="$v.participant.emergencyContacts.phoneNumber.$touch"
                    :class="{
                      'is-invalid':
                        $v.participant.emergencyContacts.phoneNumber.$error,
                      'is-valid':
                        !$v.participant.emergencyContacts.phoneNumber.$error &&
                        $v.participant.emergencyContacts.phoneNumber.$dirty,
                    }"
                  />
                </div>
                <div class="form-group">
                  <label for="title">Relation</label>
                  <input
                    id="title"
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    v-model="participant.emergencyContacts.relation"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            howDidYouHearAboutUsReasons &&
            howDidYouHearAboutUsReasons.length > 0
          "
          class="row"
        >
          <div class="col-12">
            <label>Hur hörde du talas om Utställningen Unga Forskare?</label>
          </div>
          <div class="col-12">
            <select-multiple
              value-attribute="id"
              display-attribute="name"
              :options="howDidYouHearAboutUsReasons"
              v-model="participant.howDidYouHearAboutUsReasonIds"
            />
          </div>
        </div>
        <hr />
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :value="participant.acceptedTermsAndConditions"
            disabled="true"
            :id="`toc-${$vnode.key}`"
            v-model="participant.acceptedTermsAndConditions"
            @change="$v.participant.acceptedTermsAndConditions.$touch"
            :class="{
              'is-invalid': $v.participant.acceptedTermsAndConditions.$error,
              'is-valid':
                !$v.participant.acceptedTermsAndConditions.$error &&
                $v.participant.acceptedTermsAndConditions.$dirty,
            }"
          />
          <label class="form-check-label" :for="`toc-${$vnode.key}`"
            >Genom att delta i Utställningen Unga Forskare godkänner du som deltagare att personuppgifter som behövs för deltagande i Utställningen Unga Forskare, såsom namn, e-post, skola, telefonnummer m.m. samt projektrapporter och tävlingsmaterial, får samlas in av Unga Forskare och vid behov får delas och hanteras av semifinalsarrangörer och juryledamöter i tävlingen. Kontaktuppgifter kan delas med samarbetspartners i syfte att kunna genomföra gemensamma aktiviteter. Unga Forskare lagrar personuppgifterna digitalt i max 3 år i syfte att administrera deltagande i Utställningen Unga Forskare 2025, genomföra utvärdering m.m. Unga Forskare kan komma att kontakta dig i syfte att ställa frågor om anmälan och/eller informera om sådant som rör Utställningen Unga Forskares verksamhet. Juryintervjuer kommer att hållas fysiskt/digitalt på semi- och finalnivå. Intervjun kan komma att spelas in i syfte att underlätta juryns bedömning av projekt. Videon raderas omedelbart efter att tävlingen är avslutad. Din rapport kan komma att användas för att träna en AI-assistent i syfte att vara ett hjälpmedel i juryns bedömningsprocess. AI:n är ett sidoprogram av ChatGPT och används i företaget Xylems regi. Rapporterna raderas inom ett år.</label
          >
        </div>

        <br />

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :id="`membership-${$vnode.key}`"
            v-model="acceptedMembership"
            @change="$v.acceptedMembership.$touch"
            value="true"
            disabled="true"
            :class="{
              'is-invalid': $v.acceptedMembership.$error,
              'is-valid':
                !$v.acceptedMembership.$error && $v.acceptedMembership.$dirty,
            }"
          />
          <label class="form-check-label" :for="`membership-${$vnode.key}`"
            >Deltagande i Utställningen Unga Forskare innebär medlemskap i
            Alumniföreningen Utställningen Unga Forskare (AUUF) under år 2025.
            Föreningen anordnar bland annat återträffar och andra aktiviteter
            (mer info och föreningens stadgar går att finna på föreningens
            hemsida <a href="https://www.auuf.se">www.auuf.se</a>). Medlemskapet
            innebär att föreningen har info om medlemmars för- och efternamn,
            födelseår, inträdesdatum, kön, telefonnummer, adress, postnummer,
            ort och e-postadress. Jag intygar att jag har tagit del av infon
            rörande medlemskap i AUUF.
          </label>
        </div>
        <br />

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :id="`emergencyContact-${$vnode.key}`"
            v-model="acceptedEmergencyContact"
            @change="$v.acceptedEmergencyContact.$touch"
            :value="false"
            :class="{
              'is-invalid': $v.acceptedEmergencyContact.$error,
              'is-valid':
                !$v.acceptedEmergencyContact.$error &&
                $v.acceptedEmergencyContact.$dirty,
            }"
          />
          <label
            class="form-check-label"
            :for="`emergencyContact-${$vnode.key}`"
            >Med anledning av dataskyddsförordningen GDPR godkänner jag att
            informera den person jag anger som nödkontakt om att jag lämnar
            dennes namn- och kontaktuppgifter i detta formulär och att Unga
            Forskare kan komma att kontakta personen under tävlingens gång.
            Uppgifterna lagras digitalt och fysiskt hos Unga Forskare och kommer
            att hanteras av Unga Forskares personal samt andra personer i
            verksamheten som bedöms behöva tillgång till uppgifterna för att
            kunna utföra sina uppdrag inom Utställningen Unga Forskare.
            Uppgifterna raderas direkt efter att Utställningen Unga Forskare är
            genomförd i april 2025.</label
          >
          <div
            class="small text-danger"
            v-if="$v.acceptedEmergencyContact.$error"
          >
            Måste vara accepterat
          </div>
        </div>
        <br />

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :id="`informTeacher-${$vnode.key}`"
            v-model="acceptedInformTeacher"
            @change="$v.acceptedInformTeacher.$touch"
            :value="false"
            :class="{
              'is-invalid': $v.acceptedInformTeacher.$error,
              'is-valid':
                !$v.acceptedInformTeacher.$error &&
                $v.acceptedInformTeacher.$dirty,
            }"
          />
          <label class="form-check-label" :for="`informTeacher-${$vnode.key}`"
            >Med anledning av dataskyddsförordningen GDPR godkänner jag att
            informera den person som jag anger som lärarhandledare om att jag
            lämnar dennes namn- och kontaktuppgifter i detta formulär och att
            Unga Forskare kan komma att kontakta personen i frågor som rör
            verksamheten eller för marknadsföring av Unga Forskares verksamhet.
            Uppgifterna kommer att hanteras av Unga Forskares personal samt
            andra personer i verksamheten som bedöms behöva tillgång till
            uppgifterna för att kunna utföra sina uppdrag inom Utställningen
            Unga Forskare. Unga Forskare lagrar de personuppgifter som uppges
            digitalt och fysiskt i maximalt 1 år.</label
          >
          <div class="small text-danger" v-if="$v.acceptedInformTeacher.$error">
            Måste vara accepterat
          </div>
        </div>
        <br />

        <div class="form-check form-group">
          <p>
            Jag godkänner att namn, citat, foton och film på mig som tagits och tas under och i samband med tävlingen kan publiceras i Unga Forskares och dess samarbetspartners kanaler, massmedia samt tredje parts kommunikation om Unga Forskare och Unga Forskares verksamheter som exempelvis skolor eller ett företag som skriver om oss. Det kan exempelvis innebära att en journalist får ditt telefonnummer för ett citat till en tidningsartikel. Materialet kan användas i marknadsförings- och inspirationssyfte i högst 10 år. Därefter lagras materialet av historiska skäl på Unga Forskares server samt, vad gäller tryckt material, på Unga Forskares kansli.
          </p>
          <div>
            <div
              class="small text-danger"
              v-if="$v.participant.acceptedMediaVisibility.$error"
            >
              Du måste välja ett av alternativen.
            </div>
            <div class="col">
              <input
                class="form-check-input"
                type="radio"
                :value="true"
                :id="`marketing-${$vnode.key}-yes`"
                :name="`marketing-${$vnode.key}`"
                v-model="participant.acceptedMediaVisibility"
              />
              <label class-form-check-label :for="`marketing-${$vnode.key}-yes`"
                >Ja</label
              >
            </div>
            <div class="col">
              <input
                class="form-check-input"
                type="radio"
                :value="false"
                :id="`marketing-${$vnode.key}-no`"
                :name="`marketing-${$vnode.key}`"
                v-model="participant.acceptedMediaVisibility"
              />
              <label class-form-check-label :for="`marketing-${$vnode.key}-no`"
                >Nej</label
              >
            </div>
          </div>
        </div>
        <br />
        <div class="form-check">
          <div class="text-muted">
            Läs gärna vår
            <a href="https://ungaforskare.se/integritetspolicy"
              >integritetspolicy</a
            >
            där vi berättar om hur vi arbetar med att skydda dina
            personuppgifter i enlighet med dataskyddsförordningen GDPR.
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  minValue,
  between,
  email,
  requiredIf,
  or,
  and,
  integer,
  sameAs,
} from "vuelidate/lib/validators";
import ApplicationFormSchoolPicker from "./ApplicationFormSchoolPicker.vue";
import SelectMultiple from "./SelectMultiple.vue";

const accepted = (value) => {
  return value || false;
};

const schoolIdValidator = (value) => {
  return (
    value !== undefined &&
    ((Number.isInteger(value) && value > 0) ||
      (typeof value === "string" && value !== ""))
  );
};

const birthDayValidator = (value) =>
  new Date().getYear() - new Date(value).getYear() <= 20 &&
    new Date().getYear() - new Date(value).getYear() > 0; //to not allow 5 or 6 digit birthyears

export default {
  components: {
    "school-picker": ApplicationFormSchoolPicker,
    "select-multiple": SelectMultiple,
  },
  data: function () {
    return {
      confirmEmail: "",
      schoolCityRequired: false,
      acceptedMembership: true,
      createdSchool: null,
      acceptedEmergencyContact: false,
      acceptedInformTeacher: false,
    };
  },
  props: {
    participant: {
      firstName: String,
      lastName: String,
      email: String,
      phoneNumber: String,
      diet: String,
      schoolId: Number,
      gender: String,
      birthDate: String,
      acceptedTermsAndConditions: Boolean,
      acceptedMediaVisibility: Boolean,
      emergencyContacts: {
        firstName: String,
        lastName: String,
        phoneNumber: String,
        relation: String,
      },
      howDidYouHearAboutUsReasonIds: { type: Array, default: () => [] },
      address: {
        street1: String,
        zipCode: String,
        city: String,
      },
      schoolCity: String,
    },
    howDidYouHearAboutUsReasons: Array,
    showDelete: Boolean,
    title: String,
    schools: Array,
    isDigital: Boolean,
  },
  validations: {
    participant: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      address: {
        city: {
          required,
        },
        street1: {
          required,
        },
        zipCode: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
      },
      schoolId: {
        schoolIdValidator,
      },
      phoneNumber: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(16),
      },
      birthDate: {
        required,
        birthDayValidator,
      },
      emergencyContacts: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        phoneNumber: {
          required,
          minLength: minLength(7),
          maxLength: maxLength(16),
        },
      },
      acceptedTermsAndConditions: {
        required,
        accepted,
      },
      acceptedMediaVisibility: {
        required,
      },
    },

    createdSchool: {
      address: {
        city: {
          required: requiredIf(function (participant) {
            return this.schoolCityRequired;
          }),
        },
      },
    },

    confirmEmail: {
      required,
      email,
      sameAs: sameAs(function (participant) {
        return this.participant.email;
      }),
    },
    acceptedMembership: {
      required,
      accepted,
    },
    acceptedEmergencyContact: {
      required,
      accepted,
    },
    acceptedInformTeacher: {
      required,
      accepted,
    },
  },
  methods: {
    remove() {
      this.$emit("remove");
    },
    newSchool(school) {
      this.createdSchool = school;
      this.$emit("newSchool", school);
    },
    handleSelectSchool(evt) {
      this.$v.participant.schoolId.$model = evt;
      this.schoolCityRequired =
        this.createdSchool !== null && this.createdSchool.id === evt;
    },
  },
  computed: {
    schoolClass() {
      if (this.$v.participant.schoolId.$error) {
        return "is-invalid";
      }
      if (
        !this.$v.participant.schoolId.$error &&
        this.$v.participant.schoolId.$dirty
      ) {
        return "is-valid";
      }
      return "";
    },
  },
  watch: {
    participant(newParticipant, oldParticipant) {
      this.$emit("update:participant", newParticipant);
    },
  },
  created() {
    this.$v.participant.acceptedTermsAndConditions.$touch();
    this.$v.acceptedMembership.$touch();
    this.$v.acceptedEmergencyContact.$touch();
    this.$v.acceptedInformTeacher.$touch();
    this.$emit("update:emergencyContacts", [{}]);
  },
};
</script>

<style></style>
